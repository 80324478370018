html,
body {
  font-family: "Lexend", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-optical-sizing: auto;
  font-style: normal;
}

.btn-block {
  display: block;
  width: 100%;
}

.navbar-brand {
  padding-top: 1.5rem;
}

.logo {
  font-size: 75px;
  font-weight: bold;
  margin-left: 16px;
  color: #2196F3;
}

.text-primary {
  color: #2196F3 !important;
}

.text-italic {
  font-style: italic;
}